import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import {
  useFirebaseApp,
  AnalyticsProvider,
  FirestoreProvider,
  FunctionsProvider,
} from 'reactfire';
import { PrismicProvider } from '@prismicio/react';
import { datadogRum } from '@datadog/browser-rum';
import { Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { Analytics } from './components';
import { client } from './lib/prismic';
import { CheckoutProvider } from './contexts';
import { Home, HowItWorks, OrderSuccess, Checkout, Purchase } from './pages';
import errorIcon from './images/errorIcon.svg';

const datadogRumInit = () =>
  datadogRum.init({
    applicationId: '6b62ac06-7ff4-458d-874b-3c21348d27fc',
    clientToken: 'puba7b7a8a2c988d66ed9b1d3d3e2747bca',
    site: 'datadoghq.eu',
    service: 'airtime-gift-cards',
    env: 'production',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 10,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask',
  });

const datadogRumStartSessionReplay = () =>
  datadogRum.startSessionReplayRecording();

const Unauthorized = () => (
  <div className="flex h-screen flex-col items-center justify-center gap-2 text-gray-800 dark:text-white">
    <img className="h-14 w-14" src={errorIcon} alt="Success" />
    <div className="text-center">
      <h1 className="text-lg font-medium">Unauthorized</h1>
      <p>Gift cards must be accessed via the Airtime Rewards mobile app</p>
    </div>
  </div>
);

const App = () => {
  const firebaseApp = useFirebaseApp();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const zorroUserId = params.get('zorro_user_id');
  const canViewApp = Boolean(zorroUserId);

  useEffect(() => {
    datadogRumInit();
    datadogRumStartSessionReplay();

    if (zorroUserId === 'null' || zorroUserId === null) {
      const noZorroUserIDError = new Error('No zorro user id detected');
      datadogRum.addError(noZorroUserIDError);
    }
  }, [zorroUserId]);

  return (
    <AnalyticsProvider sdk={getAnalytics(firebaseApp)}>
      <FirestoreProvider sdk={getFirestore(firebaseApp)}>
        <FunctionsProvider sdk={getFunctions(firebaseApp, 'europe-west2')}>
          <Analytics />
          <PrismicProvider client={client}>
            <Routes>
              <Route
                path="/"
                element={canViewApp ? <Home /> : <Unauthorized />}
              />
              <Route
                path="/purchase/:id"
                element={canViewApp ? <Purchase /> : <Unauthorized />}
              />
              <Route path="/how-it-works" element={<HowItWorks />} />
              <Route path="/order-success" element={<OrderSuccess />} />
              <Route
                path="/:sku"
                element={
                  canViewApp ? (
                    <CheckoutProvider>
                      <Checkout />
                    </CheckoutProvider>
                  ) : (
                    <Unauthorized />
                  )
                }
              />
            </Routes>
          </PrismicProvider>
        </FunctionsProvider>
      </FirestoreProvider>
    </AnalyticsProvider>
  );
};

export default App;
