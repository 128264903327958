import { useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useSinglePrismicDocument, PrismicRichText } from '@prismicio/react';
import { logEvent } from 'firebase/analytics';
import { useAnalytics } from 'reactfire';
import { useModal } from '../../hooks';
import Checkbox from '../Checkbox';
import Button from '../Button';
import TermsAndConditionsModal from '../TermsAndConditionsModal';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { open, handleOpen, handleClose } = useModal();
  const [document] = useSinglePrismicDocument('terms-and-conditions');
  const analytics = useAnalytics();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const zorroUserId = params.get('zorro_user_id');

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    logEvent(analytics, 'purchase_attempted');

    if (!stripe || !elements) return;

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/order-success?zorro_user_id=${zorroUserId}`,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message as string);
      logEvent(analytics, 'purchase_failed', {
        message: error.message,
      });
    } else {
      setMessage('An unexpected error occured.');
      logEvent(analytics, 'purchase_failed', {
        message: 'An unexpected error occured.',
      });
    }

    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="mt-4 space-y-4 border-t pt-4">
        <Checkbox
          id="terms-accepted"
          checked={termsAccepted}
          onChange={() => setTermsAccepted((prevState) => !prevState)}
          label={
            <>
              By clicking pay now you agree to the{' '}
              <button
                className="font-medium underline"
                type="button"
                onClick={handleOpen}
              >
                Terms and conditions
              </button>
            </>
          }
        />
        <Button type="submit" disabled={!termsAccepted} fullWidth>
          {isLoading ? 'Loading...' : 'Pay now'}
        </Button>
        {message && <p className="text-sm text-red-500">{message}</p>}
      </div>
      {document && (
        <TermsAndConditionsModal
          open={open}
          onClose={handleClose}
          copyRichText={
            <PrismicRichText
              field={document?.data['terms-and-conditions'] as any}
            />
          }
        />
      )}
    </form>
  );
};

export default CheckoutForm;
